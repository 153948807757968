<template>
  <div>
      <order-list type="1"/>
  </div>
</template>

<script>
import OrderList from '@/components/content/Home/OrderList.vue'
export default {
  components: {
    OrderList
  }
}
</script>

<style>

</style>