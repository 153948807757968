<template>
  <div>
    <list />
  </div>
</template>

<script>
import List from "../../components/admin/Transactions/List.vue";
export default {
  components: { List },
};
</script>

<style>
</style>