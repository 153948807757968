<template>
  <div> 
<v-card>
    <v-container>
      <v-card-title class="pl-0">
          В соответствии Указа Президента Кыргызской Республики «Об объявлении 2018 года
          Годом развития регионов» <br> Кыргызская фондовая биржа разработала электронную торговую площадку 
          товарно-сырьевой биржи, основными задачами которой является:
      </v-card-title>

          
<ul>
  <li>Способствовать развитию организованного товарно-сырьевого рынка Кыргызстана;</li>
  <li>Способствовать увеличению и повышению эффективности экспорта товаров из Кыргызстана;</li>
  <li>Способствовать повышению эффективности импорта товаров в Кыргызстан;</li>
  <li>Облегчить поиск продавцов и покупателей;</li>
  <li>Создать прозрачный механизм заключения биржевых сделок.</li>
</ul>
    </v-container>
</v-card>
  
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    ul li {
      margin: 10px 0;
    }
</style>