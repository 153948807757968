<template>
   <div>
      <all/>
   </div>
</template>

<script>
import All from '@/components/admin/Orders/All.vue'
export default {
   components: {
      All
   }
}
</script>

<style>

</style>