<template>
  <div>
    <trade-orders :deal="deal" />
    <template>
      <v-card class="pa-3 mb-3">
        <div id="printDeal">
          <h5 class="mb-3">
            Зарегистрировано в ЗАО «Кыргызская Фондовая Биржа»
          </h5>
          <table class="tb tb1" border="1">
            <tbody>
              <tr>
                <td>Регистрационный номер №:</td>
                <td>{{ deal.deal_number && `${deal.deal_number}-ТСС` }}</td>
              </tr>
              <tr>
                <td>Дата:</td>
                <td>{{ deal.created_at }}</td>
              </tr>
              <tr>
                <td>Ф.И.О. уполномоченного лица:</td>
                <td></td>
              </tr>
              <tr>
                <td>Должность:</td>
                 
                <td></td>
              </tr>
              <tr>
                <td>Подпись:</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <span>м.п.</span>
          <div>
            <div style="text-align: center">
              <h5>
                БИРЖЕВОЙ ДОГОВОР №
                {{ deal.deal_number && `${deal.deal_number}-ТСС` }}
              </h5>
            </div>
            <div style="display: flex; justify-content: space-between">
              <span>г. Бишкек</span>
              <span>«{{ deal.created_at }}»</span>
            </div>
          </div>
          <div>
            <template>
              <table class="tb" border="1">
                <tbody>
                  <tr>
                    <td>
                      <b
                        >Наименование Посетителя торгов -
                        {{ sortType(deal.order_type_from) }}</b
                      >
                    </td>
                    <td>
                      {{ deal.user_from_info[0].items[0].value }}
                      «{{ deal.user_from_info[0].items[1].value }}»
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Регистрационный номер Посетителя торгов -
                      {{ sortType(deal.order_type_from) }}
                    </td>
                    <td>{{ deal.user_from_name }}</td>
                  </tr>
                </tbody>
              </table>
              <p>
                , именуемый в дальнейшем «{{ sortType(deal.order_type_from) }}»,
                в лице Трейдера Участника торгов -
                {{ sortType(deal.order_type_from) }}
                <u
                  >{{ deal.user_from_info[4].items[0].value }}, действующего по
                  доверенности (прилагается)</u
                >, с одной стороны, и
              </p>
            </template>
            <template>
              <table class="tb" border="1">
                <tbody>
                  <tr>
                    <td>
                      <b>
                        Наименование Посетителя торгов -
                        {{ sortType(deal.order_type_to) }}</b
                      >
                    </td>
                    <td>
                      {{ deal.user_to_info[0].items[0].value }}
                      «{{ deal.user_to_info[0].items[1].value }}»
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Регистрационный номер Посетителя торгов -
                      {{ sortType(deal.order_type_to) }}
                    </td>
                    <td>{{ deal.user_to_name }}</td>
                  </tr>
                </tbody>
              </table>
              <p>
                , именуемый в дальнейшем «{{ sortType(deal.order_type_to) }}», в
                лице Трейдера Участника торгов -
                {{ sortType(deal.order_type_to) }}
                <u
                  >{{ deal.user_to_info[4].items[0].value }}, действующего по
                  доверенности (прилагается) </u
                >, с другой стороны, совместно именуемые в дальнейшем «Стороны»,
                заключили настоящий договор о нижеследующем:
              </p>
            </template>
          </div>
          <div style="text-align: center">
            <h5>1.ПРЕДМЕТ ДОГОВОРА</h5>
          </div>

          <p>
            1.1. Настоящий Договор заключен на основании биржевой сделки,
            совершенной в ходе двойного встречного аукциона в товарно-сырьевом
            секторе ЗАО «Кыргызская Фондовая Биржа» «{{ deal.created_at }}» г.
            (далее- Биржевая сделка) на следующих условиях:
          </p>
          <table class="tb" border="1">
            <tbody>
              <tr>
                <td>Регистрационный номер сделки</td>
                <td>{{ deal.deal_number && `${deal.deal_number}-ТСС` }}</td>
              </tr>
              <tr>
                <td>Наименование товара, код ТН ВЭД</td>
                <td>{{ deal.title }} / {{ deal.code_tnved }}</td>
              </tr>
              <tr>
                <td>Качественные показатели товара</td>
                <td>{{ deal.quality }}</td>
              </tr>
              <tr>
                <td>Сведения об упаковке товара</td>
                <td>{{ deal.packing_form }}</td>
              </tr>
              <tr>
                <td>Сведения о маркировке товара</td>
                <td>{{ deal.marking }}</td>
              </tr>
              <tr>
                <td>Цена за единицу товара (с НДС и НсП) / Валюта платежа</td>
                <td>
                  {{ validPrice(deal.price) }} / {{ deal.currency_title }}
                </td>
              </tr>
              <tr>
                <td>Количество товара / Единица измерения</td>
                <td>{{ deal.amount }} / {{ deal.weight }}</td>
              </tr>
              <tr>
                <td>Сумма сделки (с НДС и НсП)/ Валюта платежа</td>
                <td>{{ validPrice(deal.cost) }} / {{ deal.currency_title }}</td>
              </tr>
              <tr>
                <td>НДС</td>
                <td></td>
              </tr>
              <tr>
                <td>НCП</td>
                <td></td>
              </tr>
              <tr>
                <td>Сроки оплаты</td>
                <td>{{ deal.payment_date }}</td>
              </tr>
              <tr>
                <td>Местонахождение товара</td>
                <td>{{ deal.product_location }}</td>
              </tr>
              <tr>
                <td>Условия оплаты</td>
                <td>{{ deal.payment }}</td>
              </tr>
              <tr>
                <td>Условия поставки</td>
                <td>{{ deal.delivery }}</td>
              </tr>
              <tr>
                <td>Срок поставки</td>
                <td>{{ deal.delivery_date }}</td>
              </tr>
              <tr>
                <td>Дополнительные условия</td>
                <td>{{ deal.special_conditions }}</td>
              </tr>
            </tbody>
          </table>
          <p>
            1.2. Продавец в соответствии с условиями настоящего Договора
            обязуется поставить Покупателю, а Покупатель обязуется в
            установленные сроки принять и оплатить товар (далее- Товар),
            наименование, количество и качество которого предусматриваются
            условиями Биржевой сделки.<br />
            1.3. Товар должен отвечать общим критериям качества и безопасности,
            согласно действующим и применимым стандартам, а также техническим
            регламентам.<br />
            1.4. Поставка Товара с качественными показателями ниже указанных в
            настоящем Договоре не допускается. Товар с качественными
            показателями выше указанных показателей дополнительной оплате не
            подлежит и оплачивается по цене согласно условиям Биржевой
            сделки.<br />
            1.5. Продавец гарантирует, что Товар не находится под запретом
            отчуждения, арестом, не является предметом залога и другим средством
            обеспечения исполнения обязательств перед любыми физическими или
            юридическими лицами, государственными органами и государством, а
            также не является предметом любого другого отягощения или
            ограничения.<br />
          </p>
          <div style="text-align: center">
            <h5>2. ЦЕНА ТОВАРА И УСЛОВИЯ ОПЛАТЫ</h5>
          </div>
          <p>
            2.1. Цена Товара и валюта платежа устанавливаются согласно условиям
            Биржевой сделки. Цена Товара не может быть изменена Сторонами в
            течение всего срока действия настоящего Договора.<br />
            2.2. Оплата производится путем банковского перевода денежных средств
            на субсчёт Покупателя: № счёта __________________, БИК
            ________________; открытый в расчетной организации ЗАО «Кыргызская
            Фондовая Биржа», далее ЗАО «Кыргызская Фондовая Биржа» осуществляет
            банковский перевод на счёт Продавца в срок, установленный Биржевой
            сделкой.<br />
            2.3. Банковские расходы, связанные с переводом денежных средств на
            счет Продавца, оплачивает Покупатель.<br />
            2.4. Днем оплаты считается день перечисления соответствующих
            денежных средств на расчетный счет Продавца.<br />
          </p>
          <div style="text-align: center">
            <h5>3.ОБЯЗАННОСТИ СТОРОН</h5>
          </div>
          <p>
            3.1. <strong>Продавец обязуется: <br /> </strong> 3.1.1. Передать в
            собственность Покупателя Товар свободный от любых прав третьих
            лиц.<br />
            3.1.2. Нести все относящиеся к Товару расходы до момента его
            передачи Покупателю в соответствии с условиями поставки.<br />
            3.1.3. Поставить Товар соответствующего качества.<br />
            3.1.4. В случае несоответствия Товара по качеству условиям
            настоящего Договора произвести замену на Товар соответствующего
            качества. При этом, Покупатель не принимает Товар не
            соответствующего качества, а Продавец самостоятельно несет расходы,
            связанные с заменой такого Товара на Товар с качественным
            характеристиками согласно условиям настоящего Договора.<br />
            3.1.5. Обеспечить упаковку Товара за свой счет (за исключением
            случаев, когда данный вид Товара принято отгружать без упаковки).<br />
            3.1.6. Передать Покупателю в момент передачи Товара следующие
            документы:<br />
            - счёт-фактура установленной формы;<br />
            - товарная накладная;<br />
            - упаковочный лист (при необходимости);<br />
            - документ, подтверждающий страну происхождения Товара (при
            необходимости);<br />
            - сертификат/декларация соответствия;<br />
            - документ, подтверждающий качество Товара (при необходимости);<br />
            - и другие документы, согласно требованиям законодательства.<br /><br />
            3.2. <strong>Покупатель обязуется:<br /></strong> 3.2.1. Произвести
            оплату по настоящему Договору в порядке и сроки, согласно условиям
            настоящего Договора.<br />
            3.2.2. Принять Товар согласно условиям настоящего Договора,
            осуществить проверку при приемке Товара по количеству и качеству
            согласно данным транспортных (отгрузочных) и сопроводительных
            документов на Товар.<br />
            3.2.3. Получить от Продавца вместе с Товаром документы, указанные в
            п. 3.1.6. настоящего Договора.<br />
          </p>
          <div style="text-align: center">
            <h5>4.ПЕРЕХОД РИСКОВ И ПРАВА СОБСТВЕННОСТИ</h5>
          </div>
          <p>
            4.1. Продавец несет все риски случайной гибели, повреждения, порчи и
            хищения Товара до момента его передачи Покупателю согласно условиям
            поставки Товара. <br />
            4.2. Право собственности на Товар, а также все риски случайной
            гибели, повреждения, порчи и хищения Товара, переходят от Продавца к
            Покупателю с момента поставки Товара согласно условиям поставки.
            <br />
          </p>
          <div style="text-align: center">
            <h5>5.ОТВЕТСТВЕННОСТЬ СТОРОН</h5>
          </div>
          <p>
            5.1. Сроки и условия поставки Товара устанавливаются согласно
            условиям Биржевой сделки. <br />
            5.2. Товар по настоящему Договору поставляется(вид транспорта)
            транспортом, на условиях поставки, определенных Биржевой сделкой.<br />
            5.3. Товар по настоящему Договору считается поставленным Продавцом и
            принятым Покупателем (кроме случаев наличия у Покупателя претензий
            по количеству и качеству Товара):<br />
            - по количеству - в соответствии с количеством, указанным в
            транспортных (отгрузочных) документах;<br />
            - по качеству - в соответствии с данными документов, удостоверяющих
            безопасность и качество Товара.<br />
          </p>
          <div style="text-align: center">
            <h5>6.ПОРЯДОК ПОСТАВКИ И ПРИЕМКИ ТОВАРА</h5>
          </div>
          <p>
            6.1. В случае неисполнения и/или ненадлежащего исполнения Сторонами
            своих обязательств по настоящему Договору, Стороны несут
            ответственность определенную настоящим Договором и действующим
            законодательством Кыргызской Республики. <br />
            6.2. В случае если Продавец поставил Покупателю Товар, не
            соответствующий качественным показателям, установленным условиями
            Биржевой сделки, Продавец обязуется оплатить <br />
            Покупателю штраф в размере ______ % от стоимости не соответствующего
            Товара, а также <br />
            заменить на Товар надлежащего качества в ______ дневный срок с
            момента получения обоснованной претензии Покупателя. <br />
            дневный срок с момента получения <br />
            6.3. При просрочке сроков оплаты Товара, установленных Биржевой
            сделкой, Покупатель обязуется оплатить Продавцу пеню в размере
            ______ % от суммы, подлежащей оплате за каждый день просрочки.
            <br />
            6.4. Оплата неустоек, установленных п.п. 6.2. и 6.3. настоящего
            Договора, не освобождает Сторон от исполнения своих обязательств по
            настоящему Договору. <br />
          </p>
          <div style="text-align: center">
            <h5>7.ФОРС-МАЖОРНЫЕ ОБСТОЯТЕЛЬСТВА</h5>
          </div>
          <p>
            7.1. Стороны освобождаются от ответственности за частичное или
            полное неисполнение и/или ненадлежащее исполнение обязательств по
            настоящему Договору, если это неисполнение и/или ненадлежащее
            исполнение явилось следствием обстоятельств непреодолимой силы,
            возникших после заключения Договора в результате событий
            чрезвычайного характера (форс-мажор).
            <br />
            7.2. К таким событиям чрезвычайного характера относятся: наводнение,
            пожар, землетрясение,<br />
            7.1. взрыв, шторм, оседание почвы, эпидемия и иные явления природы,
            а также война или военные действия, забастовка в отрасли или
            регионе, принятие органом государственной власти или управления
            решения, повлекшего за собой невозможность исполнения настоящего
            Договора.<br />
            7.3. При наступлении и прекращении указанных в пунктах 7.1., 7.2.
            обстоятельств, Сторона, для которой создались форс- мажорные
            обстоятельства, должна немедленно известить об этом другую
            Сторону.<br />
            7.4. Надлежащим доказательством наличия указанных выше обстоятельств
            и их продолжительности должно служить письменное свидетельство
            Торгово-промышленной палаты страны Стороны, для которой возникли
            форс- мажорные обстоятельства.<br />
            7.5. При отсутствии своевременного извещения, предусмотренного в п.
            7.3., Сторона обязана возместить другой Стороне убытки, причиненные
            не извещением или несвоевременным извещением.<br />
            7.6. При наступлении форс-мажорных обстоятельств, сроки исполнения
            обязательств по настоящему Договору соразмерно увеличиваются на
            период действия таких форс-мажорных обстоятельств.<br />
          </p>
          <div style="text-align: center">
            <h5>8.РЕГИСТРАЦИЯ, ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ ДОГОВОРА</h5>
          </div>
          <p>
            8.1. Настоящий Договор подлежит обязательной регистрации в ЗАО
            «Кыргызская Фондовая Биржа» в сроки, установленные Правилами
            биржевой торговли и другими внутренними нормативными документами ЗАО
            «Кыргызская Фондовая Биржа». <br />
            8.2. Изменения и дополнения к настоящему Договору или его
            расторжение могут быть произведены в порядке и на условиях согласно
            настоящему Договору или применимому законодательству, а также по
            взаимному согласию Сторон или по решению суда. Соответствующее
            решение суда или, подписанные Сторонами изменения, дополнения,
            соглашение о расторжении настоящего Договора должны быть
            представлены Сторонами для регистрации в ЗАО «Кыргызская Фондовая
            Биржа». <br />
          </p>
          <div style="text-align: center">
            <h5>9.ДЕЙСТВИЕ ДОГОВОРА</h5>
          </div>
          <p>
            9.1. Настоящий Договор вступает в силу с момента его регистрации в
            ЗАО «Кыргызская Фондовая Биржа» и действует до полного исполнения
            Сторонами своих обязательств по настоящему Договору.
          </p>
          <div style="text-align: center">
            <h5>10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h5>
          </div>
          <p>
            10.1. Споры и разногласия, которые могут возникнуть при исполнении
            настоящего Договора, решаются путем переговоров между Сторонами, а в
            случае невозможности их разрешения, споры решаются в Арбитражной
            комиссии ЗАО «Кыргызская Фондовая Биржа».<br />
            10.2. В случае, если решение Арбитражной комиссии ЗАО «Кыргызская
            Фондовая Биржа» не устраивает Сторон, то Стороны соглашаются, что
            спор подлежит разрешению в Международном Третейском Суде (г. Бишкек)
            («МТС») в соответствии с Регламентом МТС одним арбитром, избранным в
            соответствии с этим Регламентом. Применимым материальным правом, в
            соответствии с которым будет рассматриваться спор, является
            законодательство Кыргызской Республики. Местом проведения
            третейского разбирательства является город Бишкек, Кыргызская
            Республика. Языком третейского разбирательства является русский
            язык. Решение МТС является окончательным. <br />
            10.4. Все отношения Сторон, связанные с исполнением обязательств по
            Договору, но не урегулированные его условиями, регламентируются
            действующим законодательством Кыргызской Республики.<br />
            10.5. Настоящий Договор содержит и выражает все договорные условия и
            понимание между участвующими здесь Сторонами в отношении всех
            упомянутых вопросов.<br />
            10.6. Ни одна из Сторон настоящего Договора не имеет права
            передавать свои права и обязательства по настоящему Договору третьим
            лица без письменного согласия другой Стороны.<br />
            10.7. Настоящий Договор составлен на русском языке, в трех
            идентичных экземплярах, которые имеют одинаковую юридическую
            силу.<br />
            10.8. Стороны обязаны незамедлительно уведомлять друг друга об
            изменении своих почтовых адресов, банковских реквизитов, номеров
            телефонов, телефаксов.<br />
          </p>
          <div style="text-align: center">
            <h5>11. ЮРИДИЧЕСКИЕ АДРЕСА И БАНКОВСКИЕ РЕКВИЗИТЫ СТОРОН:</h5>
          </div>
          <div id="deal">
            <div class="brdr">
              <strong>{{ sortType(deal.order_type_from) }}</strong>
            </div>
            <div class="brdr">
              <strong>{{ sortType(deal.order_type_to) }}</strong>
            </div>
            <div
              class="brdr"
              v-for="(item, k) in [deal.user_from_info, deal.user_to_info]"
              :key="k"
            >
              <div v-for="(rows, i) in item" :key="i">
                <div
                  class="bb"
                  v-for="{ field, title, value } in rows.items"
                  :key="field"
                >
                  <template
                    v-if="
                      field == 'fullname' ||
                      field == 'legal_address' ||
                      field == 'account_number_with_currency' ||
                      field == 'bank_name' ||
                      field == 'bank_code' ||
                      field == 'bank_address' ||
                      field == 'trade_fullname' ||
                      field == 'contact_information'
                    "
                  >
                    <strong
                      >{{
                        field == "account_number_with_currency"
                          ? "Банковские реквизиты расчетной организации"
                          : field == "trade_fullname"
                          ? "Трейдер"
                          : field == "contact_information"
                          ? "Контакты"
                          : title
                      }}:</strong
                    >
                    <p>{{ value }}</p>
                  </template>
                </div>
              </div>
            </div>
            <div class="brdr">
              <strong>Подпись:</strong>
            </div>
            <div class="brdr">
              <strong>Подпись:</strong>
            </div>
          </div>
        </div>
        <div v-if="comments.length > 0">
          <div class="subtitle-1 mt-3">Комментарии</div>
          <v-list>
            <v-list-item v-for="(comment, i) in comments" :key="i">
              <v-list-item-avatar>
                <v-img src="../../../assets/john.jpg"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ comment.username }}
                  <span class="font-weight-thin body-2">
                    {{ comment.created_at }}
                  </span></v-list-item-title
                >

                <v-list-item-subtitle>{{
                  comment.comment
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text
                  >Прикрепленный файл</v-list-item-action-text
                >
                <template v-if="comment.file_path">
                  <a
                    :href="`${url_api}/${comment.file_path}`"
                    target="_blank"
                    class="text-decoration-none body-2"
                    >Посмотреть</a
                  >
                </template>
                <template v-else>
                  <p class="font-weight-thin">Файлы отсутствует</p>
                </template>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
import TradeOrders from "./TradeOrders.vue";
export default {
  components: { TradeOrders },
  props: ["deal", "comments"],
  data: () => ({
    url_api: process.env.VUE_APP_BACK_API,
  }),
  methods: {
    sortType: (type) => (type == 1 ? "Продавец" : "Покупатель"),
    sortOrderUser: (type) => (type == 1 ? "Продавца" : "Покупателя"),
    validPrice(number) {
      return new Intl.NumberFormat("ru", { style: "decimal" }).format(number);
    },
  },
};
</script>

<style lang="scss" scoped>
#deal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  box-sizing: border-box;
}

.brdr {
  border: 1px solid black;
  box-sizing: content-box;
  margin-left: -1px;
  margin-top: -1px;
  padding: 5px;
}
.brdr p {
  margin-bottom: 0;
}

.tb {
  width: 100%;
  border-spacing: 0;
}
.tb td {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 5px;
}
.tb td:first-child {
  width: 50%;
}
.tb1 td:first-child {
  width: 50%;
}
p {
  font-size: 14px;
}

.bb {
  border-bottom: 1px;
}
</style>