<template>
  <depo-deals />
</template>

<script>
import DepoDeals from "../../components/admin/Deals/DepoDeals.vue";
export default {
  components: {
    DepoDeals,
  },
};
</script>

<style>
</style>