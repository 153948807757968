<template>
  <v-container>
    <all-tenders />
  </v-container>
</template>

<script>
import AllTenders from "../../components/admin/Tenders/All.vue";
export default {
  components: {
    AllTenders,
  },
};
</script>

