<template>
  <div>
    <list />
  </div>
</template>

<script>
import List from "@/components/admin/Catalog/Catalog/List";
export default {
  data: () => ({}),
  components: {
    List,
  },
};
</script>

<style></style>
