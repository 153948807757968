var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.isAddDealDialog),callback:function ($$v) {_vm.isAddDealDialog=$$v},expression:"isAddDealDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center",attrs:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.order_view.is_auction ? "Стандартный аукцион" : "Двойной встречный аукцион")+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"12"}},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.order_view.title)+" ")]),_c('span',{staticClass:"subtitle-1"},[_vm._v(" • "+_vm._s(_vm.order_view.order_type_title)+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"left":""},model:{value:(_vm.selected_offer),callback:function ($$v) {_vm.selected_offer=$$v},expression:"selected_offer"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',[_vm._v(" Купить ")])],1),_c('v-tabs-items',{model:{value:(_vm.selected_offer),callback:function ($$v) {_vm.selected_offer=$$v},expression:"selected_offer"}},[_c('v-tab-item',{staticClass:"pt-5"},[_c('v-row',[_vm._l((_vm.orderTemplate),function(field,i){return [(
                            field.field === 'price' ||
                            field.field === 'amount' ||
                            field.field === 'cost' ||
                            field.field === 'delivery' ||
                            field.field === 'payment'
                          )?_c('v-col',{key:i,attrs:{"cols":field.type === 'textarea' ||
                            field.type === 'file' ||
                            field.type === 'checkbox'
                              ? '12'
                              : field.type === 'auction_date' ||
                                field.type === 'auction_time'
                              ? '6'
                              : '6'}},[(field.type === 'select')?[_c('v-select',{attrs:{"disabled":field.field == 'type' ||
                                field.field == 'category' ||
                                !_vm.isAuction,"items":_vm.options[field.item],"item-text":"title","item-value":"id","label":field.title,"dense":"","outlined":""},on:{"click":function($event){return _vm.GET_OPTIONS(field.item)}},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]:_vm._e(),(
                              field.type === 'input' ||
                              field.type === 'textarea'
                            )?[_c('v-text-field',{attrs:{"label":field.title,"outlined":"","dense":"","disabled":!_vm.isAuction && field.field == 'price'
                                  ? true
                                  : field.field == 'amount' ||
                                    field.field == 'price' ||
                                    field.field == 'cost'
                                  ? false
                                  : true},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]:_vm._e(),(field.type === 'autocomplate')?[_c('v-text-field',{attrs:{"label":field.title,"disabled":"","outlined":"","dense":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]:_vm._e(),(field.type === 'date')?[_c('v-text-field',{attrs:{"label":field.title,"type":"date","outlined":"","dense":"","rules":[_vm.rules.isEmpty]},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]:_vm._e()],2):_vm._e()]})],2)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"orange darken-1","text":"","small":""},on:{"click":_vm.closeIsAddDealDialog}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"#78C3CC","elevation":"0","small":"","dark":""},on:{"click":_vm.createDeal}},[_vm._v(" Отправить ")]),_c('v-spacer')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }