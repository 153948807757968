<template>
  <trade-list />
</template>

<script>
import TradeList from "../../components/admin/Deals/TradeList.vue";
export default {
  components: {
    TradeList,
  },
};
</script>

<style>
