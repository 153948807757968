<template>
    <v-container class="pa-0 fill-height">
      <list/>
    </v-container>
</template>
<script>
import list from '../../components/admin/Catalog/Tnved/list.vue';
export default {
	components: { list }
};
</script>