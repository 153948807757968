var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-dialog',{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.isAddDialog),callback:function ($$v) {_vm.isAddDialog=$$v},expression:"isAddDialog"}},[_c('v-form',{ref:"prodAdd",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Добавить товар ")]),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.prodTemplate),function(field,i){return _c('v-col',{key:i,staticClass:"px-2",attrs:{"cols":field.col}},[(field.type === 'textarea')?[_c('v-textarea',{attrs:{"auto-grow":"","outlined":"","rows":"2","label":field.title},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]:_vm._e(),(field.type === 'input')?[_c('v-text-field',{attrs:{"dense":"","label":field.title,"outlined":"","rules":[_vm.isEmpty]},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]:_vm._e(),(field.type === 'file')?[(field.value)?_c('v-row',{staticClass:"mb-3"},[_vm._l((field.value),function(file,i){return _c('v-col',{key:i,staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-card',[(
                          file.type.includes(
                            'image/jpeg',
                            'image/png',
                            'image/jpg'
                          )
                        )?[_c('v-img',{attrs:{"src":_vm.fileurl(file),"height":"150"}})]:[_c('v-card',{attrs:{"height":"150"}},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" mdi-file-document ")])],1)],1)]],2)],1)}),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-file-input',{attrs:{"label":field.title,"rules":field && [_vm.isMaxFile],"multiple":"","outlined":"","dense":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)],2):_vm._e()]:_vm._e()],2)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","dark":"","small":"","plain":"","elevation":"0"},on:{"click":_vm.closeProdDialog}},[_vm._v(" Закрыть ")]),_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0","color":"#78C3CC","dark":"","small":""},on:{"click":_vm.createProd}},[_vm._v("Добавить")])],1),_c('div',{attrs:{"id":"blog"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }