<template>
  <div>
    <all-tenders />
  </div>
</template>

<script>
import AllTenders from "../../components/admin/Tenders/All.vue";
export default {
  components: { AllTenders },
};
</script>

<style>
</style>