<template lang="">
   <deal-list status="2"/> 
</template>
<script>
import DealList from "@/components/admin/Deals/List.vue";
export default {
  components: {
    DealList,
  },
};
</script>
<style lang="">
</style>