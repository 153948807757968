<template>
   <deal-list status="1"/>
</template>
<script>
import DealList from '@/components/admin/Deals/List.vue'
export default {
   components: {
      DealList
   }
}
</script>