<template>
  <account-list />
</template>

<script>
import AccountList from "../../components/admin/Transactions/AccountList.vue";

export default {
  components: { AccountList },
};
</script>

<style>
</style>